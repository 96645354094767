$(document).ready(function() {
  var breakpoint = 1200;

  // Click on contact buttons
  var codeRun = false;
  function checkAction(e) {
    /*if (codeRun === true) {
      codeRun = false;
      return;
    }*/
    if ($(this).hasClass('is-open')) {
      codeRun = false;
      return;
    }
    e.preventDefault();

    $(this).parents('ul').find('.is-open').removeClass('is-open');
    $(this).toggleClass('is-open');

    codeRun = true;
  }
  $('.site-footer .js-contact-button').on('click', checkAction);

  $('.js-contact-button').blur(function() {
    if ($(this).hasClass('is-open')) {
      $(this).removeClass('is-open');
    }
  });
  // This should only happen on desktop for the phone button in header
  if(window.innerWidth >= breakpoint) {
    $('.nav-header .js-contact-button').on('click', checkAction);
  }

});
