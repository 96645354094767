$(document).ready(function() {

  /*
   * Category expander
   */
  $('.js-promo-button').click(function() {
    // Expand or collapse toggleable categories
    $(this).toggleClass('is-open');
    $('.category-wrapper').toggleClass('show');
    // Change the text of the button
    if ($(this).hasClass('is-open')) {
      $(this).text('Shop-Kategorien ausblenden');
      $(this).append('<i class="fa fa-chevron-up" aria-hidden="true"></i>');
      //$('html,body').animate({scrollTop: $('#top-anchor').offset().top}, 'fast');
    } else {
      $(this).text('Alle Shop-Kategorien einblenden');
      $(this).append('<i class="fa fa-chevron-down" aria-hidden="true"></i>');
      //$('html,body').animate({scrollTop: $('#top-anchor').offset().top}, 'fast');
    }
  });
});
