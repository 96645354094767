$(document).ready(function() {

  var breakpoint = 1200;

  /*
   * Initializing headroom
   */
  $('.main-header').headroom();

  /*
   * Initializing hamburger
   */
  $('.js-toggle-mobile-nav').click(function() {
    $(this).toggleClass('is-active');

    var navHeader = document.querySelector('.nav-header');

    navHeader.classList.toggle('is-active');
  });


  // Toggle mini cart in header, only if showing full header
  function toggleMiniCart() {
    $('.js-toggle-cart').on('click', function(e) {
      if (window.innerWidth > breakpoint) {
        e.preventDefault();
        $(this).toggleClass('shopping-cart-open');
        $('.mini-cart').toggleClass('mini-cart--active');
      }
    });
  }
  toggleMiniCart();


  // Toggle account menu in header, only if showing full header
  function toggleAccountMenu() {
    $('.js-toggle-account-menu').on('click', function(e) {
      if (window.innerWidth > breakpoint) {
        e.preventDefault();
        $('.account-menu').toggleClass('is-open');
      }
    });
  }
  toggleAccountMenu();


  // Check the header items also on resize
  window.addEventListener('resize', function() {
    setTimeout(function() {
      toggleMiniCart();
      toggleAccountMenu();
    }, 100);
  });

});
