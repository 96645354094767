$(document).ready(function() {

  // Toggle shipping address item
  $('.js-toggle-shipping-address').click(function() {

    $('.shipping-address-items button').each(function() {
      $(this).toggleClass('hidden');
    });

    $('.shipping-address-item').each(function() {
      if($(this).hasClass('shipping-address-item--active')) {
        $(this).removeClass('shipping-address-item--active');
      }
      else {
        $(this).addClass('shipping-address-item--active');
      }
    });

  });


});
