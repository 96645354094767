$(document).ready(function() {

  /*
   * Search input suggestions
   */

  $('#searchInput').focus(function() {
    $('.search-box-overlay').removeClass('fade');
  });

  $('#searchInput').focusout(function() {
    $('.search-suggestions').hide();
    $('.search-box-overlay').addClass('fade');
  });

  $('#searchInput').keyup(function() {
    $('.search-suggestions').show();

    if ($(this).val() === "") {
      $('.search-suggestions').hide();
    }
  });
});
